import { FormikHelpers } from 'formik';
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { AppPage, useSnackbar } from '../../components-v2/shared';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';
import { Company } from '../../types';
import { CompanyDetailsForm } from './CompanyDetailsForm';
import { CompanyDetailsFormValues } from './CompanyDetailsForm.helpers';

export const CompanyCreate: React.FC<{}> = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { companies, router, fetchActiveUser } = store;

    const createCompany = (
        values: CompanyDetailsFormValues,
        formikHelpers: FormikHelpers<CompanyDetailsFormValues>
    ) => {
        const omittedValues = ['logo_file'];
        const formattedValues: Company = omit(values, omittedValues) as any;

        companies
            .create(formattedValues)
            .then(async (companyId) => {
                await fetchActiveUser(undefined, companyId);
                router.goTo(DefaultRoutes.CompanyDashboard, { ...router.params, companyId }, store);
            })
            .catch(() => {
                addSnackbar('Failed to create company.', { variant: 'error' });
                formikHelpers.setSubmitting(false);
            });
    };

    return (
        <AppPage>
            <CompanyDetailsForm title="Add Company" onSubmit={createCompany} />
        </AppPage>
    );
});
